@font-face {
  font-family: 'gotham';
  src: url('../public/fonts/GothamCondensed-Black.woff') format('truetype');
}
@font-face {
  font-family: 'merri';
  src: url('../public/fonts/Merriweather-Regular.woff') format('truetype');
}

body {
  margin: 0px;
  padding: 0;
  background-color: #fff;
  color: #000;
  font-size: 1.125rem;
}

header {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 100%;
  width: 100vw;
}

.wrapper {
  width: 80%;
}

.main-title {
  text-align: center;
  color: #000;
}

h1, h2 {
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 60px;
  font-style: normal;
  font-weight: 900;
  font-family: 'gotham';
  align-self: center;
}

h1 {
  font-size: 66px;
}
h2 {
  font-size: 40px;
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.img-wrapper {
  height: 200px;
}

.img-wrapper img {
  height: 100%;
}

ul li {
  color: yellow;
  list-style: none;
  text-decoration: none;
  border: 1px solid #000;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  background-color: orange;
  position: relative;
}

.linkto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

ul li :hover {
  color: red;
  text-decoration: underline;
}

ul li :focus {
  color: red;
  text-decoration: none;
}

ul li :visited {
  color: #000;
}

.form-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formulaire {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
}

.result-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.result-right {
  display: flex;
  flex-direction: column;
}

.result-social {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.wrapper-table {
  position: relative;
  padding-top: 10px;
}

.formulaire .rightPart {
  background-color: #F4F6FB;
  display: flex;
  flex-direction: column;
  font-family: "merri";
  font-weight: 400;
  font-size: 1rem;
  color: #32323c;
  line-height: 1.5625rem;
  padding: 0 40px;
}

.inputLabel {
  color: #6D7989;
  display: inline-block;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  width: 200px;
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 20px;
  font-weight: 900;
  font-family: 'gotham';
}

input {
  border: 1px solid #6D7989;
  font-size: 1.125rem;
  width: 100%;
  color: #6D7989;
  box-sizing: border-box;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  padding: 12px 14px 12px;
  border-radius: 21.5px;
  font-style: italic;
}
input :focus {
  outline: none;
}
.checkItem {
  padding-top: 17px;
}
.checkItem input {
  position: relative;
  width: 2%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #6D7989;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: unset;
}
.checkItem input:checked {
  border: 1px solid #6D7989;
}

.checkItem input:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #6D7989;
}

.checkItem input:hover {
  cursor: pointer;
}
.checkItem ::after {
  content: "Centrer l'image";
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(110%,-50%);
  font-size: 14px;
  color: #6D7989;
  font-weight: 300;
  font-family: 'merri';
  width: max-content;
}

.checkItem.gif ::after {
  content: "Gif";
}

.checkItem.static ::after {
  content: "Statique";
}

.wrapperTypeImage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 100px;
}

/* remove basic style of button */
button {
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: linear-gradient(90deg, #E2DA8B 0%, #B7934D 100%);
  border-radius: 32px;
  width: 100%;
  height: 65px;
  margin-top: 27px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}

button.copyButton ::after{
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: 'gotham';
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  content: "copier la signature";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

button.copyButton.copied ::after {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: 'gotham';
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  content: "Copié !";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

textarea {
  border: 1px solid #6D7989;
  font-size: 1.125rem;
  width: 100%;
  color: #6D7989;
  box-sizing: border-box;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  padding: 12px 14px 12px;
  height: 100px;
  border-radius: 20px;
  font-family: "merri";
  font-style: italic;
}


/* MEDIA QUERIE */

/* PHONE */
@media (max-width: 1280px) {
  .formulaire {
    flex-direction: column;
  }
}

/* DESKTOP */
@media (min-width: 1280px) {
  .rightPart {
    width: 40%;
    height: 100vh;
    overflow: scroll;
  }
}